<template>
  <section class="max-w-6xl mx-auto px-4 pt-40" id="story">
    <h1 class="text-3xl lg:text-5xl text-gray-100 text-center mb-6">Story</h1>

    <!-- Desktop Story Section -->
    <div class="bg-story-clr rounded-xl p-16">
      <div class="grid grid-cols-6 h-72">
        <div
          class="col-span-2 relative border-t-4 border-black pt-4 mark-top-left"
        >
          <h1 class="my-4 text-primary text-xl">
            Exchange <br />
            Integrations
          </h1>
          <div class="ml-4 text-sm leading-5 flex gap-7">
            <ul class="list-disc text-gray-400 text-sm">
              <li>Binance</li>
              <li>FXT (TBA)</li>
            </ul>
            <ul class="list-disc text-gray-400 text-sm">
              <li>Coinbase</li>
              <li>Bitfinex (TBA)</li>
            </ul>
          </div>
        </div>
        <div
          class="col-span-4 relative border-t-4 border-r-4 rounded border-black pt-4 mark-top-left mark-top-right"
        >
          <div class="flex justify-between">
            <div>
              <h1 class="my-4 text-primary text-xl">
                Technical Analysis <br />
                Framework
              </h1>
              <div class="ml-4 text-sm leading-5 flex gap-7">
                <ul class="list-disc text-gray-400 text-sm">
                  <li>
                    Trading Bots <br />
                    (Python & NodeJS)
                  </li>
                </ul>
                <ul class="list-disc text-gray-400 text-sm">
                  <li>
                    Strategy Build <br />
                    (HTML Forms)
                  </li>
                </ul>
              </div>
              <ul class="list-disc text-gray-600 text-sm ml-4 mt-2">
                <li>SDK to build your own strategies</li>
              </ul>
            </div>
            <div class="pr-4">
              <h1 class="my-4 text-primary text-xl">API (Q3)</h1>
              <div class="ml-4 text-sm leading-5 flex gap-7">
                <ul class="list-disc text-gray-400 text-sm">
                  <li>Triger Trades</li>
                  <li>Monitor</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-7 h-60 border-t-4 border-l-4 border-black">
        <div class="col-span-1"></div>
        <!------------------- 

            STEP 4

        ------------------->
        <div class="relative col-span-3 mark-top-left pt-4">
          <h1 class="my-4 text-primary text-xl">Portfolios (Q4)</h1>
          <div class="ml-4 text-sm leading-5 flex gap-9">
            <ul class="list-disc text-gray-400 text-sm">
              <li>AI Portfolios</li>
              <li class="text-gray-500">
                Portfolio strategies <br />
                built by AI
              </li>
              <li class="text-gray-500">Auto Managed</li>
            </ul>
            <ul class="list-disc text-gray-400 text-sm">
              <li>Managed Portfolios</li>
              <li class="text-gray-500">
                User can built own <br />
                portfolios
              </li>
            </ul>
          </div>
        </div>
        <!------------------- 

        STEP 5

        ------------------->
        <div class="relative col-span-3 -mt-1 mark-top-left pt-4">
          <h1 class="my-4 text-primary text-xl">Telegram BOT</h1>
          <ul class="list-disc text-gray-400 text-sm ml-4">
            <li>Trigger trades and more</li>
          </ul>
        </div>
      </div>

      <div class="grid grid-cols-5 h-60 border-t-4 border-r-4 border-black">
        <div class="col-span-2 pt-4 relative mark-top-left">
          <h1 class="my-4 text-primary text-xl">Market Place (Q4)</h1>
          <div class="ml-4 text-sm leading-5 flex gap-9">
            <ul class="list-disc text-gray-400 text-sm">
              <li>
                Sell & Buy <br />
                Portfolios
              </li>
            </ul>
            <ul class="list-disc text-gray-400 text-sm">
              <li>
                Sell & Buy <br />
                Strategies
              </li>
            </ul>
          </div>
        </div>
        <div class="col-span-2 pt-4 relative mark-top-left">
          <h1 class="my-4 text-primary text-xl">Market Place (Q4)</h1>
          <div class="ml-4 text-sm leading-5 flex gap-9">
            <ul class="list-disc text-gray-400 text-sm">
              <li>
                Enable strategies to <br />
                run futures and <br />
                options trading on <br />
                supported exchanges
              </li>
            </ul>
            <ul class="list-disc text-gray-400 text-sm">
              <li>
                Sell & Buy <br />
                Strategies
              </li>
            </ul>
          </div>
        </div>
        <div class="col-span-1 pt-4 relative mark-top-left">
          <h1 class="my-4 text-primary text-xl">
            Mobile Apps <br />
            (2022)
          </h1>
          <ul class="list-disc text-gray-400 text-sm">
            <li>iOS & Android</li>
          </ul>
        </div>
      </div>

      <div class="grid grid-cols-5 h-60 border-t-4 border-black">
        <div class="col-span-3 pt-4 relative mark-top-left">
          <h1 class="my-4 text-primary text-xl">
            Open Source & <br />Defi (2022)
          </h1>
          <div class="grid grid-cols-3 pr-16">
            <ul class="list-disc text-gray-400 text-sm ml-4">
              <li>
                Open source as <br />
                much as we can
              </li>
              <li class="mt-1">
                Venture into the <br />
                DEFI space
              </li>
            </ul>
            <ul class="list-disc text-gray-400 text-sm ml-4">
              <li>
                Community <br />
                Hackatons
              </li>
              <li class="mt-1 text-gray-500">
                Yield <br />
                Farming
              </li>
            </ul>
            <ul class="list-disc text-gray-500 text-sm ml-4">
              <li>
                AMM's (Market <br />
                Makers)
              </li>
              <li class="mt-1">
                Liquidity <br />
                Pools
              </li>
            </ul>
          </div>
        </div>
        <div class="col-span-2 pt-4 relative mark-top-left">
          <h1 class="my-4 text-primary text-xl">
            Market Maker AI & <br />
            Strategies (2020)
          </h1>
          <ul class="list-disc text-gray-400 text-sm ml-4">
            <li>
              Create your own market <br />
              maker on any DEX/CEX
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Story",
};
</script>

<style lang="scss" scoped>
.mark-top-left:before {
  content: "\2713";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  font-weight: bold;
  transform: translate(-5px, -50%);
  width: 25px;
  height: 25px;
  color: #212121;
  background-color: #00a34b;
  border-radius: 50%;
}

// TODO: not sure about how I placed it
// .phase-2:before {
//   top: calc(-1rem);
// }

.mark-top-right:after {
  content: "\2713";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -1%;
  right: -15px;
  font-weight: bold;
  transform: translate(-5px, -50%);
  width: 25px;
  height: 25px;
  color: #212121;
  background-color: #00a34b;
  border-radius: 50%;
}
</style>
